import { useTranslation } from 'react-i18next'
import { difference, merge } from 'lodash'
import { ViewMoreButton, CardError, StateSelectField } from 'components'
import {
  TextBlock,
  Font,
  TextField,
  ProgressBar,
  FieldBlock,
  Button,
  ButtonBlock,
  Section,
  Sheet,
  ContentBlock,
  DateField,
  useToast,
} from '@politechdev/blocks-design-system'
import React, { useContext, useEffect, useState } from 'react'
import { useReactRouter, useRequest } from 'hooks'
import classNames from 'classnames/bind'
import { useDebounce } from 'use-debounce'
import {
  deleteImportError,
  postImportError,
} from 'requests/duplicatePrevention'
import moment from 'moment'
import { getExtraFields } from 'duplicatePrevention/record/utils'
import styles from './RecordError.module.scss'
import { RecordContext } from '../RecordContext/RecordContext'
import DiffIndicator from '../DiffIndicator/DiffIndicator'

const cx = classNames.bind(styles)

const RecordError = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { setToast } = useToast()
  const [animated, setAnimated] = useState(false)

  const {
    resolvedFields,
    setResolvedFields,
    errorRecord,
    importReq,
    importReqLoading,
    duplicateReqLoading,
    reqDuplicateUpdate,
    row,
    setRow,
    readOnly,
    setReadOnly,
    hasDuplicateWithSameExternalId,
  } = useContext(RecordContext)

  const [debouncedErrorRecordChange] = useDebounce(errorRecord, 200)

  const [debouncedFirstName] = useDebounce(resolvedFields.first_name, 200)
  const [debouncedLastName] = useDebounce(resolvedFields.last_name, 200)
  const [debouncedPhoneNumber] = useDebounce(
    resolvedFields.primary_phone_number,
    200
  )
  const [debouncedEmailAddress] = useDebounce(
    resolvedFields.primary_email_address,
    200
  )
  const [debouncedExternalId] = useDebounce(resolvedFields.external_id, 200)

  useEffect(() => {
    !animated && setAnimated(true)
  }, [debouncedErrorRecordChange])

  useEffect(() => {
    reqDuplicateUpdate(resolvedFields)
  }, [
    debouncedFirstName,
    debouncedLastName,
    debouncedPhoneNumber,
    debouncedEmailAddress,
    debouncedExternalId,
  ])

  const onSuccess = () => {
    setToast({
      message: t('Success'),
      variant: 'success',
    })
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      importReq()
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setReadOnly(false)
      if (row === 0) {
        setRow(1)
      }
    }, 1500)
  }

  const deleteImportErrorRequest = useRequest(deleteImportError, { onSuccess })

  const saveImportErrorRequest = useRequest(postImportError, { onSuccess })

  useEffect(() => {
    saveImportErrorRequest.isLoading && setReadOnly(true)
  }, [saveImportErrorRequest.isLoading])

  useEffect(() => {
    deleteImportErrorRequest.isLoading && setReadOnly(true)
  }, [deleteImportErrorRequest.isLoading])

  const isMissingFirstName =
    !errorRecord.first_name && !resolvedFields.first_name
  const isMissingLastName = !errorRecord.last_name && !resolvedFields.last_name

  const extraFields = getExtraFields(errorRecord)

  const saveAsNew = () =>
    saveImportErrorRequest.makeRequest(match.params.importId, errorRecord.id, {
      row_data: merge(errorRecord, resolvedFields, {
        birth_date:
          resolvedFields.birth_date || errorRecord.birth_date
            ? moment(
                resolvedFields.birth_date || errorRecord.birth_date
              ).toISOString()
            : undefined,
      }),
    })

  const discardRecord = () => {
    deleteImportErrorRequest.makeRequest(match.params.importId, errorRecord.id)
  }

  const isSaveAsNewInvalid =
    isMissingFirstName ||
    isMissingLastName ||
    readOnly ||
    duplicateReqLoading ||
    hasDuplicateWithSameExternalId

  return (
    <Section label={`${t('Attempting to import row')} ${errorRecord.id}`}>
      <ContentBlock collapsed>
        <Sheet>
          <div
            className={cx('animation-block', {
              'animation-block--animated': animated,
            })}
            onAnimationEnd={() => setAnimated(false)}
          />
          <ProgressBar
            show={
              saveImportErrorRequest.isLoading ||
              deleteImportErrorRequest.isLoading
            }
          />
          <CardError
            hide={
              !saveImportErrorRequest.hasErrors &&
              !deleteImportErrorRequest.hasErrors
            }
            message={t('Something went wrong resolving this record')}
          />
          <ButtonBlock>
            <Button.Accent disabled={isSaveAsNewInvalid} onClick={saveAsNew}>
              {t('Save as new')}
            </Button.Accent>
            <Button.Secondary
              disabled={readOnly || duplicateReqLoading}
              onClick={discardRecord}
            >
              {t('Discard')}
            </Button.Secondary>
          </ButtonBlock>
          <Section label={t('Name')}>
            <DiffIndicator section="fullName" />
            <FieldBlock>
              <TextField
                label={t('First name')}
                value={
                  resolvedFields.first_name !== undefined
                    ? resolvedFields.first_name
                    : errorRecord.first_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    first_name: val,
                  }))
                }
                required
              />
              <TextField
                label={t('Middle name')}
                value={
                  resolvedFields.middle_name !== undefined
                    ? resolvedFields.middle_name
                    : errorRecord.middle_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    middle_name: val,
                  }))
                }
              />
              <TextField
                label={t('Last name')}
                value={
                  resolvedFields.last_name !== undefined
                    ? resolvedFields.last_name
                    : errorRecord.last_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    last_name: val,
                  }))
                }
                required
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary phone')}>
            <DiffIndicator section="primary_phone_number" />
            <FieldBlock>
              <TextField
                type="tel"
                value={
                  resolvedFields.primary_phone_number !== undefined
                    ? resolvedFields.primary_phone_number
                    : errorRecord.primary_phone_number?.replace(/\D/g, '') ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_phone_number: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary email')}>
            <DiffIndicator section="primary_email_address" />
            <FieldBlock>
              <TextField
                value={
                  resolvedFields.primary_email_address !== undefined
                    ? resolvedFields.primary_email_address
                    : errorRecord.primary_email_address ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_email_address: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Address')}>
            <DiffIndicator section="fullResidentialAddress" />
            <FieldBlock>
              <TextField
                label={t('Address line 1')}
                value={
                  resolvedFields.residential_address?.line_one !== undefined
                    ? resolvedFields.residential_address.line_one
                    : errorRecord.residential_address?.line_one ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      line_one: val,
                    },
                  }))
                }
              />
            </FieldBlock>
            <FieldBlock>
              <TextField
                label={t('Address line 2')}
                value={
                  resolvedFields.residential_address?.line_two !== undefined
                    ? resolvedFields.residential_address.line_two
                    : errorRecord.residential_address?.line_two ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      line_two: val,
                    },
                  }))
                }
              />
            </FieldBlock>
            <FieldBlock>
              <TextField
                label={t('City')}
                value={
                  resolvedFields.residential_address?.city !== undefined
                    ? resolvedFields.residential_address.city
                    : errorRecord.residential_address?.city ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      city: val,
                    },
                  }))
                }
              />
            </FieldBlock>
            <FieldBlock>
              <StateSelectField
                label={t('State')}
                state={
                  resolvedFields.residential_address?.state !== undefined
                    ? resolvedFields.residential_address.state
                    : errorRecord.residential_address?.state ?? ''
                }
                onSelect={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      state: val,
                    },
                  }))
                }
              />
            </FieldBlock>
            <FieldBlock>
              <TextField
                label={t('Zipcode')}
                value={
                  resolvedFields.residential_address?.zipcode !== undefined
                    ? resolvedFields.residential_address.zipcode
                    : errorRecord.residential_address?.zipcode ?? ''
                }
                error={
                  resolvedFields.residential_address?.zipcode !== undefined &&
                  !resolvedFields.residential_address.zipcode.match(
                    /^\d{5}(-\d{4})?$/
                  )
                }
                errorMsg={t('Invalid zip code')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      zipcode: val,
                    },
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Date of birth')}>
            <DiffIndicator show={difference.birth_date} />
            <FieldBlock>
              <DateField
                value={
                  resolvedFields.birth_date !== undefined
                    ? resolvedFields.birth_date
                    : errorRecord.birth_date ?? ''
                }
                onChange={val => {
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    birth_date: val,
                  }))
                }}
              />
            </FieldBlock>
          </Section>
          <Section label={t('External ID')}>
            <DiffIndicator show={difference.external_id} />
            <FieldBlock>
              <TextField
                value={
                  resolvedFields.external_id !== undefined
                    ? resolvedFields.external_id
                    : errorRecord.external_id ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    external_id: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          {!!extraFields.length && (
            <ViewMoreButton name="field" count={extraFields.length}>
              {extraFields.map(
                field =>
                  field.value && (
                    <TextBlock>
                      <Font.Label>{t(field.label)}</Font.Label>
                      <Font.Copy>{field.value}</Font.Copy>
                    </TextBlock>
                  )
              )}
            </ViewMoreButton>
          )}
          <ProgressBar show={duplicateReqLoading && !importReqLoading} />
        </Sheet>
      </ContentBlock>
    </Section>
  )
}

export default RecordError
