import { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { TurfSelectField, View, ViewContainer } from 'components'
import {
  ButtonBlock,
  Button,
  DateField,
  Sheet,
  FieldBlock,
  PageHeader,
} from '@politechdev/blocks-design-system'
import {
  AutoTableProvider,
  useAutoTable,
  useQueryParams,
  useFilters,
  ReportModalProvider,
  useCurrent,
  useTurfs,
} from 'contexts'
import { useRouteQueryParams } from 'hooks'
import PetitionReportsPicker from 'petitionReports/PetitionReportsPicker/PetitionReportsPicker'
import PetitionReportsTable from 'petitionReports/PetitionReportsTable/PetitionReportsTable'
import { reportCategories, reportTypes, defaultReportType } from '../configs'
import { PetitionReportsProvider } from './PetitionReportsProvider'

const defaultStartDate = moment().subtract(7, 'days').toISOString()
const defaultEndDate = moment().toISOString()

const PetitionReports = () => {
  const { t } = useTranslation()
  const { setTableId } = useAutoTable()
  const { clearFiltersOnTableIdChange } = useFilters()
  const { queryParams, setQueryParams } = useQueryParams()

  const { reportType } = queryParams

  const {
    currentUser: {
      turf: { id: topLevelTurfId },
    },
  } = useCurrent()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  const [turfId, setTurfId] = useState(+queryParams.turfId || topLevelTurfId)
  const [dateRange, setDateRange] = useState({
    start: moment(queryParams.startDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.startDate, 'YYYYMMDD').toISOString()
      : defaultStartDate,
    end: moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
      ? moment(queryParams.endDate, 'YYYYMMDD').toISOString()
      : defaultEndDate,
  })
  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  useEffect(() => {
    const defaultParams = {}
    if (
      !queryParams.startDate ||
      !moment(queryParams.startDate, 'YYYYMMDD', true).isValid() ||
      !queryParams.endDate ||
      !moment(queryParams.endDate, 'YYYYMMDD', true).isValid()
    ) {
      defaultParams.startDate = moment(defaultStartDate).format('YYYYMMDD')
      defaultParams.endDate = moment(defaultEndDate).format('YYYYMMDD')
    }
    if (!queryParams.turfId) {
      defaultParams.turfId = topLevelTurfId
    }
    if (!queryParams.reportType) {
      defaultParams.reportType = defaultReportType
      setTableId(defaultReportType)
    } else {
      setTableId(queryParams.reportType)
    }
    setQueryParams(defaultParams, 'replace')
  }, [])

  const [categoryId, setCategoryId] = useState(
    Object.values(reportCategories).find(c =>
      c.reportTypes.includes(reportType)
    )?.id ||
      Object.values(reportCategories).find(c =>
        c.reportTypes.includes(defaultReportType)
      )?.id
  )
  useEffect(() => {
    if (reportType) {
      setTableId(reportType)
      clearFiltersOnTableIdChange()
      setQueryParams({ reportType })
    }
  }, [reportType])

  const changeReportType = reportType => {
    setTableId(reportType)
    clearFiltersOnTableIdChange()
    setQueryParams({ reportType })
  }

  const changeTurf = turfId => {
    setTurfId(turfId)
    setQueryParams({ turfId })
  }

  const changeDateRange = (start, end) => {
    if (!start || !end) return
    setDateRange({
      start,
      end,
    })
    setQueryParams({
      startDate: moment.tz(start, 'UTC').format('YYYYMMDD'),
      endDate: moment.tz(end, 'UTC').format('YYYYMMDD'),
    })
  }

  const changeReportCategory = categoryId => {
    setCategoryId(categoryId)
    changeReportType(reportCategories[categoryId].defaultType)
  }

  return (
    <View>
      <PageHeader title={t('petition reports')}>
        <ButtonBlock justify="right">
          <a
            href={reportTypes[reportType]?.dataDictionaryUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Button.Accent>{t('Data dictionary')}</Button.Accent>
          </a>
        </ButtonBlock>
      </PageHeader>
      <ViewContainer loading={turfRefreshIsLoading}>
        <Sheet>
          <FieldBlock variant="medium">
            <DateField
              type="range"
              label={t('Date range')}
              onChange={changeDateRange}
              startDate={dateRange.start}
              endDate={dateRange.end}
            />
            <TurfSelectField
              label={t('Turf')}
              value={turfId}
              onSelect={changeTurf}
              isExpanded
              showArchived
            />
          </FieldBlock>
        </Sheet>
        <PetitionReportsPicker
          categoryId={categoryId}
          reportType={reportType}
          reportCategories={reportCategories}
          changeReportCategory={changeReportCategory}
          changeReportType={changeReportType}
          reportTypes={reportTypes}
        />
        <ReportModalProvider>
          {!!reportType && (
            <PetitionReportsTable
              reportType={reportType}
              dateRange={dateRange}
              turfId={turfId}
            />
          )}
        </ReportModalProvider>
      </ViewContainer>
    </View>
  )
}

export default () => {
  const [{ reportType }] = useRouteQueryParams()

  return (
    <PetitionReportsProvider>
      <AutoTableProvider
        persistSelectedFilter
        isFrontend
        enableQueryParams
        enableFilters
        enableSort
        showDownloadButton
        showFilterEditor
        showQuickSearch
        showTotals
        freezeLeftColumns={1}
        defaultFilters={reportTypes[reportType]?.defaultFilters || []}
      >
        <PetitionReports />
      </AutoTableProvider>
    </PetitionReportsProvider>
  )
}
